import React, { useState } from "react"

import Root from "../components/root"
import classes from "./portfolio.module.css"

import Logo from "../assets/web_resources/logo-white.png"
import { graphql } from "gatsby"
import Dropdown from "../components/Dropdown"
import Layout from "../components/layout"
import clsx from "clsx"

const PortfolioGridItem = ({ title, videos, desc, isReversed = false }) => {
  return (
    <div
      className={`mb-32 flex flex-col items-center xl:items-start ${
        isReversed ? "xl:flex-row-reverse" : "xl:flex-row"
      }`}
    >
      <div className={clsx(classes.videoCard, "w-full xl:w-1/2")}>
        <iframe
          frameBorder="0"
          width="100%"
          height="100%"
          src={videos?.[0]}
          allowFullScreen
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allow="autoplay"
        />
      </div>

      <div className="xl:w-1/2 mx-4 text-center mt-8 xl:text-left xl:mt-0">
        <p className="text-xl xl:text-4xl">{title}</p>
        <p className="mt-4 text-sm xl:text-lg">{desc}</p>
      </div>
    </div>
  )
}

const PortfolioPage = ({ data }) => {
  const [isDropdownOpen, setisDropdownOpen] = useState(false)
  const portfolio = data.allContentfulPortfolio.nodes
  const [loadMore, setLoadMore] = useState(false)

  var size = loadMore ? portfolio?.length : 6

  return (
    <Layout>
      <Root className={classes.root}>
        <div className="relative max-w-xl w-11/12 xl:w-full pt-6 xl:pt-20 xl:max-w-6xl mx-auto">
          {isDropdownOpen && <Dropdown white3={true} />}
          {!isDropdownOpen && (
            <div
              className={classes.logo}
              onClick={e => setisDropdownOpen(!isDropdownOpen)}
            >
              <img src={Logo} alt="Logo" />
            </div>
          )}
          <div onClick={e => setisDropdownOpen(false)}>
            <div className="mb-20 max-w-xs mx-auto text-center">
              <div className={classes.ourEdits}>OUR EDITS</div>
              <div className={classes.ourEdits}>OUR EDITS</div>
            </div>
            {portfolio?.slice(0, size)?.map((item, index) => {
              return (
                <PortfolioGridItem
                  key={index}
                  videos={item?.videoUrls}
                  title={item?.title}
                  image={item?.thumbnail}
                  desc={item?.description?.description}
                  isReversed={index % 2 !== 0}
                />
              )
            })}
          </div>

          {!loadMore && portfolio?.length > 6 ? (
            <div
              onClick={e => setLoadMore(!loadMore)}
              style={{ cursor: "pointer" }}
              className="text-xl xl:text-3xl font-black mt-12 xl:mt-24 text-center uppercase"
            >
              Give me more
            </div>
          ) : (
            <div
              onClick={e => setLoadMore(!loadMore)}
              style={{ cursor: "pointer" }}
              className="text-xl xl:text-3xl font-black mt-12 xl:mt-24 text-center uppercase"
            >
              Show less
            </div>
          )}
        </div>
      </Root>
    </Layout>
  )
}

export const query = graphql`
  query PortfolioQuery {
    allContentfulPortfolio(sort: { fields: portfolioId }) {
      nodes {
        id
        title
        videoUrls
        thumbnail {
          fluid(maxWidth: 320) {
            src
          }
        }
        description {
          description
        }
      }
    }
  }
`

export default PortfolioPage
